import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      children: [
        // default
        {
          name: 'ダッシュボード',
          path: '/',
          component: () => import('@/views/dashboard/Dashboard'),
        },
        // Table
        {
          name: '副反応疑い報告',
          path: 'table/:maker',
          component: () => import('@/views/dashboard/Table'),
        },
        // Table
        {
          name: '死亡報告',
          path: 'death',
          component: () => import('@/views/dashboard/TableForDeath'),
        },
        // Table
        {
          name: '心筋炎/心膜炎報告',
          path: 'myocarditis',
          component: () => import('@/views/dashboard/TableForMyocarditis'),
        },
        // Table
        {
          name: '健康被害認定一覧',
          path: 'approved',
          component: () => import('@/views/dashboard/TableApproved'),
        },
        // Table
        {
          name: '認定された副反応一覧',
          path: 'approved_disease',
          component: () => import('@/views/dashboard/TableApprovedDisease'),
        },
        // About
        {
          name: 'About',
          path: 'about',
          component: () => import('@/views/dashboard/About'),
        },
      ],
    },
  ],
})
