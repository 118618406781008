import Vue from 'vue'
import VueI18n from 'vue-i18n'

import ja from 'vuetify/lib/locale/ja'

Vue.use(VueI18n)

const messages = {
  ja: {
    ...require('@/locales/ja.json'),
    $vuetify: ja,
  },
}

export default new VueI18n({
  locale: 'ja',
  fallbackLocale: 'ja',
  messages,
})
